import Vue from "vue";
export default class Master extends Vue.extend({
    name: 'Master',
    /************************************/
    /* Data Section *
    /************************************/
    data() {
        return {};
    },
    /************************************/
    /* Computed Section *
    /************************************/
    computed: {},
    /************************************/
    /* Watcher Section *
    /************************************/
    watch: {},
    /************************************/
    /* Method Section *
    /************************************/
    methods: {
        setTheme() {
            return this.$vuetify.theme.dark = true;
        }
    },
    created() {
        this.setTheme();
    },
    activated() {
        this.setTheme();
    }
}) {
}

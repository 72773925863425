import { constants } from '@/common/constant/constants';
import { UrlHelper } from '../url-helper-service';
import { HttpService } from './http-service';
let isRefreshing = false;
let failedQueue = [];
/* ignore next because refresh token code is commented out */
function processQueue(error, accessToken) {
    failedQueue.forEach((promise) => {
        if (error) {
            promise.reject(error);
        }
        else {
            promise.resolve(accessToken);
        }
    });
    isRefreshing = false;
    failedQueue = [];
}
/* ignore next because refresh token code is commented out */
function isAuthError(error) {
    if (error && error.response != undefined && error.response.data && error.response.status === 401) {
        if (error.response.data.message && error.response.data.message == "Unauthorized. Access token is missing or invalid.") {
            return true;
        }
        else if (typeof (error.response.data) == "string" && error.response.data.startsWith('APIM') == false) {
            return true;
        }
    }
    return false;
}
function isPermissionError(error) {
    return (error && error.response != undefined && error.response.data && error.response.status === 401 && error.response.data.startsWith('APIM'));
}
function isBadRequest(error) {
    return (error && error.response != undefined && error.response.status === 400);
}
function formateBadRequestResponse(error) {
    var lineSeprator = "<br/>";
    if (error.response) {
        console.log(error.response);
        var res = "API Status:" + error.response.statusText;
        res += lineSeprator;
        if (error.response.data && error.response.data.errors) {
            res += "Validation Errors";
            res += lineSeprator;
            for (var key in error.response.data.errors) {
                for (var errorIndex in error.response.data.errors[key]) {
                    res += error.response.data.errors[key][errorIndex];
                    res += lineSeprator;
                }
            }
        }
        else if (error.response.data) {
            if (error.response.data[""] && error.response.data[""].length > 0) {
                res += lineSeprator;
                res += error.response.data[""];
            }
            else if (error.response.data.title.includes('Graph API') || error.response.data.detail.includes('Graph API')) {
                var GraphApiReponse = error.response.data.substring(error.response.data.indexOf('{'), error.response.data.lastIndexOf('}') + 1);
                var GraphApiReponseJson = JSON.parse(GraphApiReponse);
                res += lineSeprator;
                res += GraphApiReponseJson["odata.error"].message.value;
            }
            else {
                res += lineSeprator;
                res += error.response.data.title;
            }
        }
        return res;
    }
    else {
        return "API Status: BadRequest";
    }
}
/* ignore next because refresh token code is commented out */
function replaceOldToken(request, accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
    return request;
}
//function redirectToSignIin() {
//    var url = window.location.href
//    var arr = url.split("/");
//    var Domain = arr[0] + "//" + arr[2];
//    window.location.href = Domain + "/" + "Session/SigIn?CurrentController=" + arr[3];
//}
export function setupResponseInterceptor(httpService) {
    /* ignore next */
    const onRejected = (error) => {
        const originalRequest = error.config;
        if (isAuthError(error)) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                })
                    .then((accessToken) => {
                    return httpService(replaceOldToken(originalRequest, accessToken));
                })
                    .catch((err) => {
                    return Promise.reject(err);
                });
            }
            isRefreshing = true;
            return new Promise((resolve, reject) => {
                if (!httpService.store) {
                    return;
                }
                httpService.store.dispatch("RefreshToken").then((response) => {
                    if (response.isLoggedOut == true) {
                        UrlHelper.redirectToSignIn();
                        return;
                    }
                    let idToken = response.accessToken;
                    if (idToken && idToken != '') {
                        processQueue(null, idToken);
                        var CurrentReqWithNewToken = replaceOldToken(originalRequest, idToken);
                        //CurrentReqWithNewToken.then(res => {
                        //    resolve(res);
                        //});
                        //httpService(replaceOldToken(originalRequest, idToken)).then(res => {
                        //    resolve(res);
                        //});
                        //resolve(httpService(replaceOldToken(originalRequest, idToken)));
                        resolve(replaceOldToken(originalRequest, idToken));
                        //resolve();
                    }
                    else {
                        const responseError = new Error('Error on refreshing user token');
                        responseError.status = 403;
                        processQueue(responseError);
                        reject(responseError);
                        // redirect to main page in case of failure to refresh the user token.
                        if (HttpService.router) {
                            alert('Unable to refresh token. Please refresh your page');
                            //HttpService.router.push('/');
                        }
                        else
                            UrlHelper.redirectToSignIn();
                    }
                });
                //resolve();
            }).then((r) => {
                return httpService(r);
            });
        }
        else if (isPermissionError(error)) {
            let errorMessage = 'Your account is not allowed to access this resource.';
            if (error && error.response && error.response.data && error.response.data.split('-').length > 1) {
                errorMessage = error.response.data.split('-')[1];
            }
            alert(errorMessage);
            httpService.store.dispatch(constants.apiUrl.masterModuleUrl.loaderUrl, false);
        }
        else if (isBadRequest(error)) {
            var ErrorString = formateBadRequestResponse(error);
            alert(ErrorString);
            httpService.store.dispatch(constants.apiUrl.masterModuleUrl.loaderUrl, false);
        }
        return Promise.reject(error);
    };
    httpService.interceptors.response.use((response) => response, onRejected);
}

import { constants } from "@/common/constant/constants";
import { HttpHelper } from '@/common/helper/http-helper-service';
export default class FormServices {
}
//static getFormContentByFormId = (formId: string) => {
//    return HttpHelper.getData(constants.apiUrl.formUrls.getFormContentByFormId.replace("{id}", formId));
//}
//From AzureFunction
FormServices.getFormContentByFormId = (form) => {
    return HttpHelper.postData(constants.apiUrl.AzureFunctionsUrls.getFormById, form);
};
FormServices.submitFormResponse = (formResponse) => {
    return HttpHelper.postData(constants.apiUrl.AzureFunctionsUrls.sendFormResponse, formResponse);
};
FormServices.sendFormEmail = (emailModel, header) => {
    return HttpHelper.postDataWithAdditionalHeader(constants.apiUrl.AzureFunctionsUrls.sendFormEmail, emailModel, header);
};

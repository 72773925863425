export var Enums;
(function (Enums) {
    let FormElementType;
    (function (FormElementType) {
        FormElementType["ShortAnswer"] = "Short_Answer";
        FormElementType["LongAnswer"] = "Long_Answer";
        FormElementType["MultipleChoice"] = "Multiple_Choice";
        FormElementType["Checkbox"] = "Checkbox";
        FormElementType["Number"] = "Number";
        FormElementType["Email"] = "Email";
        FormElementType["PhoneNumber"] = "Phone_Number";
        FormElementType["Link"] = "Link";
        FormElementType["Date"] = "Date";
        FormElementType["Time"] = "Time";
        FormElementType["Dropdown"] = "Dropdown";
        FormElementType["Heading1"] = "Heading_1";
        FormElementType["Heading2"] = "Heading_2";
        FormElementType["Heading3"] = "Heading_3";
        FormElementType["Label"] = "Label";
        FormElementType["Text"] = "Text";
        FormElementType["Divider"] = "Divider";
        FormElementType["SubmitButton"] = "Submit_Button";
        FormElementType["FormSubmissionMessage"] = "Form_Submission_Message";
        FormElementType["Toggle"] = "Toggle";
        FormElementType["Question"] = "Question";
        FormElementType["QuestionAndShortAnswer"] = "Question_Short_Answer";
        FormElementType["QuestionAndLongAnswer"] = "Question_Long_Answer";
        FormElementType["QuestionAndCheckbox"] = "Question_Checkbox";
        FormElementType["QuestionAndNumber"] = "Question_Number";
        FormElementType["QuestionAndEmail"] = "Question_Email";
        FormElementType["QuestionAndLink"] = "Question_Link";
        FormElementType["QuestionAndDate"] = "Question_Date";
        FormElementType["QuestionAndTime"] = "Question_Time";
        FormElementType["QuestionAndDropdown"] = "Question_Dropdown";
        FormElementType["QuestionAndMultipleChoice"] = "Question_MultipleChoice";
        FormElementType["QuestionAndPhoneNumber"] = "Question_PhoneNumber";
        FormElementType["QuestionAndToggle"] = "Question_Toggle";
        FormElementType["ConditionalLogic"] = "Conditional_Logic";
    })(FormElementType = Enums.FormElementType || (Enums.FormElementType = {}));
})(Enums || (Enums = {}));

import Vue from 'vue';
import FormPreview from '../component/form-preview-component.vue';
import formService from '@/common/services/form-service';
import { filter, find } from 'lodash';
import { Enums } from '../../common/helper/enum';
export default class Form extends Vue.extend({
    name: 'Form',
    /************************************/
    /* Component Section *
    /************************************/
    components: {
        'form-preview-component': FormPreview
    },
    /************************************/
    /* Computed Section *
    /************************************/
    computed: {
        formId() {
            return this.$route.params.id;
        }
    },
    /************************************/
    /* Data Object Section *
    /************************************/
    data() {
        return {
            //formContent: {} as formContentResponseModel           
            formTitle: "",
            formElements: [],
            formElementDialogSearchText: "",
            showPreview: false,
            savingForm: false,
            formElementOptionDialog: false,
            formElementTextBoxFocused: false,
            insertAtIndex: null,
            activeElement: null,
            submitButtonElement: {
                elementName: "Submit", elementOptions: [], maxLength: 0, isRequired: false,
                elementType: Enums.FormElementType.SubmitButton, formElementId: "", linkedToId: "", payload: null, inputValue: "", placeHolder: ""
            },
            formSubmissionMessageElement: {
                elementName: "Thanks for completing this form!", elementOptions: [], maxLength: 0, isRequired: false,
                elementType: Enums.FormElementType.FormSubmissionMessage, formElementId: "", linkedToId: "", payload: null, inputValue: "", placeHolder: ""
            },
            showFormSubmissionMessageElement: false,
            fetchingFormContent: false,
            formPreviewKey: 0,
            serviceProviderId: 0,
            isLoaderShow: true,
            isFormSubmitted: false,
            warningMessage: 'This Form is already submitted!!',
            version: 1,
            state: ''
        };
    },
    /************************************/
    /* Method Section *
    /************************************/
    methods: {
        async getFormById() {
            this.fetchingFormContent = true;
            try {
                let formId = this.$route.params.id;
                let docId = this.$route.params.key;
                let ticketId = this.$route.params.ticketid;
                if (formId != "" && docId != "") {
                    const model = { id: docId, formId: formId, ticket: ticketId };
                    let response = await formService.getFormContentByFormId(model);
                    if (response.state == "Submitted") {
                        this.isLoaderShow = false;
                        this.isFormSubmitted = true;
                        return;
                    }
                    else {
                        this.state = response.state;
                        this.formTitle = response.title;
                        this.serviceProviderId = response.serviceProviderId;
                        this.version = response.version;
                        this.formElements = filter(response.formElements, function (formElementOption) {
                            return formElementOption.elementType != Enums.FormElementType.SubmitButton && formElementOption.elementType != Enums.FormElementType.FormSubmissionMessage;
                        });
                        let submitButtonElement = find(response.formElements, function (formElement) {
                            return formElement.elementType == Enums.FormElementType.SubmitButton;
                        });
                        if (submitButtonElement != null) {
                            this.submitButtonElement = submitButtonElement;
                        }
                        let formSubmissionMessageElement = find(response.formElements, function (formElement) {
                            return formElement.elementType == Enums.FormElementType.FormSubmissionMessage;
                        });
                        if (formSubmissionMessageElement != null) {
                            this.showFormSubmissionMessageElement = true;
                            this.formSubmissionMessageElement = formSubmissionMessageElement;
                        }
                        this.formPreviewKey += 1;
                        this.isLoaderShow = false;
                    }
                }
            }
            catch (e) {
                this.isLoaderShow = false;
                this.isFormSubmitted = true;
                this.warningMessage = "Error occured, form does not exist";
                console.error("Error in fetching form content by form Id");
                console.error(e);
            }
            this.fetchingFormContent = false;
        },
        callbackEvent_FormPreview(value) {
            this.isLoaderShow = false;
            this.isFormSubmitted = true;
        }
    },
    async created() {
        await this.getFormById();
    },
}) {
}

const v1QuerySting = "api-version=1.0";
export function setupRequestInterceptor(HttpService) {
    //HttpService.interceptors.request.use(
    //    (config: AxiosRequestConfig) => {
    //        if (config.url != null && config.url.indexOf("api-version") == -1) {
    //            if (config.url.indexOf("?") != -1) {
    //                config.url = config.url + "&" + v1QuerySting;
    //            }
    //            else {
    //                config.url = config.url + "?" + v1QuerySting;
    //            }
    //        }
    //        return {
    //            ...config,
    //            headers: {
    //                ...config.headers,
    //                'Ocp-Apim-Subscription-Key': ((window as any).ApiSubscriptionKey) as string                    
    //            },
    //        };           
    //    },
    //    /* ignore next */
    //    (error: AxiosError) => {
    //        return Promise.reject(error);
    //    },
    //);
}

import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
Vue.use(Vuetify);
export default new Vuetify({
    icons: {
        iconfont: "fa",
        values: {
            cancel: "fas fa-ban",
            clear: "fal fa-times",
            delete: "fal fa-times",
            checkboxOff: "fal fa-circle",
            checkboxOn: "fas fa-check-circle",
            expand: "fal fa-angle-down",
            dropdown: "fal fa-angle-down",
            radioOn: "fal fa-dot-circle",
            radioOff: "fal fa-circle",
        },
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                base: "#ffffff",
                primaryLight: "#eae8e8",
                primary: "#f5f5f5",
                primaryDark: "#e5e5e5",
                primaryxDark: "#939dac",
                primaryxDarkAlt: "#c8c8ca",
                primaryxxDark: "#adacac",
                primaryxxxDark: "#d8d7d7",
                secondaryxLight: "#cecaca",
                secondaryLight: "#939dac",
                secondary: "#40444a",
                secondaryAlt: "#c7c5c5",
                accentLight: "#c377e0",
                accent: "#82B1FF",
                accentDark: "#4dc9f6",
                error: "#FF5252",
                errorLight: "#fc636b",
                info: "#2196F3",
                success: "#4CAF50",
                warningLight: "#ffc700",
                warning: "#c59503",
                warningDark: "#DF7E00",
                font: "#333333",
                fontReverse: "#ffffff",
                fontxReverse: "#bdbfc3",
                fontLight: "#a7a7a7",
                fontxLight: "#00000",
                tertiary: "#07a3ab",
                tertiaryDark: "#D5F4EE",
                tertiaryLight: "#a0d0d3",
                tertiaryxLight: "#303d3e",
                overlay: "#212121",
                overlayLight: "#212121",
                anchorBlack: "#333333",
                disable: "#c5c5c5",
                unviewed: "#DCEDEE",
                unviewedLight: "#e6e5e5",
                warningxLight: "#e1c87c",
                errorxLight: "#FFA8A8",
                alphabg: "#adacac",
            },
            dark: {
                base: "#2B2C30",
                primaryLight: "#202225",
                primary: "#36393F",
                primaryDark: "#4A4D52",
                primaryxDark: "#5a5a5a",
                primaryxDarkAlt: "#5a5a5a",
                primaryxxDark: "#161719",
                primaryxxxDark: "#2F3136",
                secondaryxLight: "#939dac",
                secondaryLight: "#939dac",
                secondary: "#8e8c8c",
                secondaryAlt: "#40444a",
                accentLight: "#c377e0",
                accent: "#82B1FF",
                accentDark: "#4dc9f6",
                error: "#FF5252",
                errorLight: "#fc636b",
                info: "#2196F3",
                success: "#4CAF50",
                warningLight: "#ffc700",
                warning: "#c59503",
                warningDark: "#DF7E00",
                font: "#ffffff",
                fontReverse: "#333333",
                fontxReverse: "#303237",
                fontLight: "#a7a7a7",
                fontxLight: "#e2e2e2",
                tertiary: "#8BF0DE",
                tertiaryDark: "#475E5F",
                tertiaryLight: "#5d8582",
                tertiaryxLight: "#303d3e",
                overlay: "#848484",
                overlayLight: "#848484",
                anchorBlack: "#c3d3af",
                disable: "#777777",
                unviewed: "#38464A",
                unviewedLight: "#3d4047",
                warningxLight: "#463f28",
                errorxLight: "#933D3E",
                alphabg: "#707070",
            },
        },
    },
});

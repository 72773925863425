const rootFormUrlAPI = window.formApiUrlBase;
const rootAzureFunctionUrl = window.AzureFunctionUrl;
export const apiConstants = {
    apiController: {
        form: 'form',
    },
    apiAction: {
        formUrls: {
            getformcontentbyformid: "content/{id}",
        },
        AzureFunctionsUrls: {
            getFormById: "form",
            sendFormResponse: "sendformresponse",
            sendFormEmail: "sendemailforsubmittedform"
        }
    }
};
export const constants = {
    apiUrl: {
        masterModuleUrl: {
            defaultmasterDialog: "MasterModule/ShowDialog",
            closeDialog: "MasterModule/AbortLeave",
            loaderUrl: "loaderModule/ShowLoader",
        },
        formUrls: {
            getFormContentByFormId: rootFormUrlAPI + '/' + apiConstants.apiController.form + '/' + apiConstants.apiAction.formUrls.getformcontentbyformid,
        },
        AzureFunctionsUrls: {
            getFormById: rootAzureFunctionUrl + "/api/" + apiConstants.apiAction.AzureFunctionsUrls.getFormById,
            sendFormResponse: rootAzureFunctionUrl + "/api/" + apiConstants.apiAction.AzureFunctionsUrls.sendFormResponse,
            sendFormEmail: rootAzureFunctionUrl + "/api/" + apiConstants.apiAction.AzureFunctionsUrls.sendFormEmail
        },
    },
    AppVersion: "api-version=1.0",
};

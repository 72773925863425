import { Enums } from "@/common/helper/enum";
import FormServices from "@/common/services/form-service";
import { cloneDeep, filter, find, findIndex, map } from "lodash";
import { DateTime } from "luxon";
import Vue from "vue";
import { email, maxLength, required, requiredIf } from "vuelidate/lib/validators";
import { emailStandard, extractEmailAndUrls, validateEmail, validateNumeric } from "../../common/helper/custom-validator";
import FormResponseView from "./form-response-view-component.vue";
export default class FormPreviewElement extends Vue.extend({
    name: "FormPreviewElement",
    components: {
        'form-response-view-component': FormResponseView
    },
    props: {
        formElements: {
            type: Array,
            default: []
        },
        formTitle: {
            type: String,
            default: ""
        },
        serviceProviderId: {
            type: Number,
            default: 0
        },
        formSubmissionMessageElement: {
            type: Object
        },
        submitButtonElement: {
            type: Object
        },
        version: {
            type: Number,
            default: 1
        },
        state: {
            type: String,
            default: ''
        }
    },
    validations: {
        formPreviewElements: {
            $each: {
                inputValue: {
                    required: requiredIf(function (formPreviewModel) {
                        return formPreviewModel.isRequired;
                    }),
                },
                inputArray: {
                    required: requiredIf(function (formPreviewModel) {
                        return formPreviewModel.isRequired;
                    })
                }
            }
        },
        emailAddress: {
            email,
            required,
            maxLength: maxLength(200),
            emailStandard
        }
    },
    computed: {
        timeOptions() {
            let startTimeArray = [];
            let startTime = DateTime.utc().set({ hour: 6, minute: 30, second: 0 });
            for (let i = 0; i < 24; i++) {
                for (let j = 1; j <= 2; j++) {
                    startTime = startTime.plus({ minute: 30 });
                    let timeIn12HourFormat = startTime.toFormat("h:mm a");
                    startTimeArray.push({ id: timeIn12HourFormat, time: timeIn12HourFormat });
                }
            }
            return startTimeArray;
        },
        emailLabelContent() {
            if (this.emailSent === undefined && this.partialSubmissionForm == false && this.isSendingEmail) {
                return "Sending Email..";
            }
            else if (this.emailSent === true && this.partialSubmissionForm == false && !this.isSendingEmail) {
                return `Email sent to ${this.emailAddress}`;
            }
            return "";
        },
        shortAnswerElementId() {
            return Enums.FormElementType.ShortAnswer;
        },
        longAnswerElementId() {
            return Enums.FormElementType.LongAnswer;
        },
        multipleChoiceElementId() {
            return Enums.FormElementType.MultipleChoice;
        },
        checkboxElementId() {
            return Enums.FormElementType.Checkbox;
        },
        numberElementId() {
            return Enums.FormElementType.Number;
        },
        emailElementId() {
            return Enums.FormElementType.Email;
        },
        phoneNumberElementId() {
            return Enums.FormElementType.PhoneNumber;
        },
        linkElementId() {
            return Enums.FormElementType.Link;
        },
        dateElementId() {
            return Enums.FormElementType.Date;
        },
        timeElementId() {
            return Enums.FormElementType.Time;
        },
        dropdownElementId() {
            return Enums.FormElementType.Dropdown;
        },
        heading1ElementId() {
            return Enums.FormElementType.Heading1;
        },
        heading2ElementId() {
            return Enums.FormElementType.Heading2;
        },
        heading3ElementId() {
            return Enums.FormElementType.Heading3;
        },
        questionElementId() {
            return Enums.FormElementType.Question;
        },
        labelElementId() {
            return Enums.FormElementType.Label;
        },
        textElementId() {
            return Enums.FormElementType.Text;
        },
        dividerElementId() {
            return Enums.FormElementType.Divider;
        },
        toggleElementId() {
            return Enums.FormElementType.Toggle;
        },
        formPreviewElementList() {
            //Fetching the list of elements which are not hidden
            return filter(this.formPreviewElements, function (element) { return element.hideElement == false; });
        }
    },
    /************************************/
    /* Data Section *
    /************************************/
    data() {
        return {
            formPreviewElements: [],
            formPreviewValidationElements: [],
            isFormFilled: false,
            checkboxArray: [],
            conditionalLogicBlocks: [],
            partialSubmissionForm: false,
            conditionalLogicFormElements: [],
            formIsSubmitting: false,
            //send form copy to email
            showEmailDialog: false,
            emailAddress: "",
            submittedFormDate: "",
            formEmailComponentId: "form-email-component-id",
            emailHTML: "",
            emailSent: undefined,
            isSendingEmail: false,
            emailKey: "",
        };
    },
    /************************************/
    /* Methods Section *
    /************************************/
    methods: {
        formattedInputValue(formElement) {
            if (formElement && formElement.inputValue) {
                return DateTime.fromFormat(formElement.inputValue, "yyyy-MM-dd")
                    .setLocale(navigator.language)
                    .toLocaleString(DateTime.DATE_SHORT)
                    .replace(/\//g, "-");
            }
            return "";
        },
        dataSet(formPreviewElements, index) {
            if (formPreviewElements.inputArray != null && formPreviewElements.elementType == "Checkbox") {
                if (formPreviewElements.inputArray.length > 0) {
                    formPreviewElements.inputValue = JSON.stringify(formPreviewElements.inputArray);
                }
                else {
                    formPreviewElements.inputValue = "";
                }
                this.checkConditionalLogicOnElementAfterChange(formPreviewElements);
            }
        },
        customFormValiation(event, formPreviewElements, index) {
            setTimeout(() => {
                if (formPreviewElements.elementType == Enums.FormElementType.Email) {
                    formPreviewElements.error = validateEmail(formPreviewElements.inputValue);
                }
                else if ((formPreviewElements.elementType == Enums.FormElementType.Number || formPreviewElements.elementType == Enums.FormElementType.PhoneNumber) && !validateNumeric(event, formPreviewElements.inputValue)) {
                    formPreviewElements.inputValue = formPreviewElements.inputValue.replace(/[^\d+]/g, '');
                }
            }, 0);
        },
        getTimeIn12HourFormat(time) {
            let timeArray = time.split(":");
            let hour = timeArray[0];
            let minute = timeArray[1];
            var ap = "AM";
            if (hour > 11) {
                ap = "PM";
            }
            if (hour > 12) {
                hour = hour - 12;
            }
            if (hour == 0) {
                hour = 12;
            }
            if (hour < 10 && hour.toString().length == 1) {
                hour = "0" + hour;
            }
            if (minute < 10 && minute.toString().length == 1) {
                minute = "0" + minute;
            }
            var timeString = hour + ':' + minute + ap;
            return timeString;
        },
        initiateFormPreviewElements() {
            this.formPreviewElements = [];
            let _this = this;
            let conditionalLogicFormElements = filter(this.formElements, function (element, index) {
                if (element.elementType == Enums.FormElementType.ConditionalLogic) {
                    _this.conditionalLogicFormElements.push({ conditionalLogicFormElements: element, index: index });
                    return true;
                }
                return false;
            });
            this.conditionalLogicBlocks = [];
            for (let conditionalLogicFormElement of conditionalLogicFormElements) {
                if (conditionalLogicFormElement.payload != null) {
                    this.conditionalLogicBlocks.push(conditionalLogicFormElement.payload);
                }
            }
            let formElementsForUI = filter(this.formElements, function (element) { return element.elementType != Enums.FormElementType.ConditionalLogic; });
            for (let formElement of formElementsForUI) {
                let placeholder = '';
                if (formElement.elementType != Enums.FormElementType.Dropdown) {
                    if (formElement.elementName) {
                        placeholder = formElement.elementName;
                    }
                    else if (formElement.placeHolder) {
                        placeholder = formElement.placeHolder;
                    }
                }
                let formPreviewElement = {
                    formElementId: formElement.formElementId,
                    inputValue: formElement.inputValue,
                    placeholder: placeholder,
                    elementType: formElement.elementType,
                    isRequired: formElement.isRequired,
                    elementOptions: formElement.elementOptions,
                    inputArray: [],
                    linkedToId: formElement.linkedToId || "",
                    error: false,
                    payload: formElement.payload,
                    hideElement: this.hideElementBasedOnAction(formElement)
                };
                if (this.state != "Draft") {
                    if (formPreviewElement.elementType == Enums.FormElementType.Heading1 || formPreviewElement.elementType == Enums.FormElementType.Heading2 || formPreviewElement.elementType == Enums.FormElementType.Heading3 || formPreviewElement.elementType == Enums.FormElementType.Label || formPreviewElement.elementType == Enums.FormElementType.Text || formPreviewElement.elementType == Enums.FormElementType.Question) {
                        formPreviewElement.inputValue = formElement.elementName;
                    }
                    else if (formPreviewElement.elementType == Enums.FormElementType.MultipleChoice && formElement.elementName != null && formElement.elementName != "") {
                        formPreviewElement.inputValue = JSON.parse(formElement.elementName);
                    }
                    else if (formPreviewElement.elementType == Enums.FormElementType.Toggle && formElement.inputValue != null && formElement.inputValue != "") {
                        formPreviewElement.inputValue = JSON.parse(formElement.inputValue);
                    }
                    else if (formPreviewElement.elementType == Enums.FormElementType.Dropdown && formElement.elementName != null && formElement.elementName != "") {
                        formPreviewElement.inputValue = JSON.parse(formElement.elementName);
                    }
                    else if (formPreviewElement.elementType == Enums.FormElementType.QuestionAndCheckbox || formPreviewElement.elementType == Enums.FormElementType.Checkbox) {
                        this.setCheckboxInputValues(formPreviewElement);
                    }
                    if (formPreviewElement.elementType == Enums.FormElementType.Text) {
                        formPreviewElement.inputValue = extractEmailAndUrls(formElement.elementName);
                    }
                }
                else {
                    if (formPreviewElement.elementType == Enums.FormElementType.Heading1 || formPreviewElement.elementType == Enums.FormElementType.Heading2 || formPreviewElement.elementType == Enums.FormElementType.Heading3 || formPreviewElement.elementType == Enums.FormElementType.Label || formPreviewElement.elementType == Enums.FormElementType.Text || formPreviewElement.elementType == Enums.FormElementType.Question) {
                        formPreviewElement.inputValue = formElement.inputValue;
                    }
                    else if ((formPreviewElement.elementType == Enums.FormElementType.MultipleChoice || formPreviewElement.elementType == Enums.FormElementType.Dropdown || formPreviewElement.elementType == Enums.FormElementType.Toggle) && formElement.inputValue != null && formElement.inputValue != "") {
                        formPreviewElement.inputValue = JSON.parse(formElement.inputValue);
                    }
                    else if ((formPreviewElement.elementType == Enums.FormElementType.QuestionAndCheckbox || formPreviewElement.elementType == Enums.FormElementType.Checkbox) && formElement.inputValue != null && formElement.inputValue != "") {
                        formPreviewElement.inputArray = JSON.parse(formElement.inputValue);
                    }
                    if (formPreviewElement.elementType == Enums.FormElementType.Text) {
                        formPreviewElement.inputValue = extractEmailAndUrls(formElement.inputValue);
                    }
                }
                this.formPreviewElements.push(formPreviewElement);
            }
            //check if conditions fullfills aftercomming from drafts
            this.checkConditionalLogicDuringFill(formElementsForUI);
        },
        setCheckboxInputValues(formPreviewElement) {
            if (formPreviewElement.elementType == Enums.FormElementType.Checkbox) {
                formPreviewElement.elementOptions.forEach((cbox, index) => {
                    let checkboxValue = false;
                    if (typeof cbox.value == "string") {
                        checkboxValue = cbox.value === "true";
                    }
                    if (checkboxValue) {
                        formPreviewElement.inputArray.push(index);
                    }
                });
                formPreviewElement.inputValue = JSON.stringify(formPreviewElement.inputArray);
            }
        },
        checkConditionalLogicDuringFill(formElementsForUI) {
            for (let formElement of formElementsForUI) {
                let placeholder = '';
                if (formElement.elementType != Enums.FormElementType.Dropdown) {
                    if (formElement.elementName) {
                        placeholder = formElement.elementName;
                    }
                    else if (formElement.placeHolder) {
                        placeholder = formElement.placeHolder;
                    }
                }
                let formPreviewElement = {
                    formElementId: formElement.formElementId,
                    inputValue: formElement.inputValue,
                    placeholder: placeholder,
                    elementType: formElement.elementType,
                    isRequired: formElement.isRequired,
                    elementOptions: formElement.elementOptions,
                    inputArray: [],
                    linkedToId: formElement.linkedToId || "",
                    error: false,
                    payload: formElement.payload,
                    hideElement: this.hideElementBasedOnAction(formElement)
                };
                this.checkConditionalLogicOnElementAfterChange(formPreviewElement);
            }
        },
        hideElementBasedOnAction(formElement) {
            if (this.conditionalLogicBlocks.length > 0) {
                for (let conditionalLogicBlock of this.conditionalLogicBlocks) {
                    let formElementIdToCompare = formElement.linkedToId != null && formElement.linkedToId != "" ? formElement.linkedToId : formElement.formElementId;
                    let actionBlockContainingElement = find(conditionalLogicBlock.then, function (action) { return action.formElementIds.includes(formElementIdToCompare); });
                    if (actionBlockContainingElement != null) {
                        //Checking against element's default values
                        let areConditionsAlreadyMet = this.checkIfTheConditionsForAFormElementIsAlreadyFulfilled(conditionalLogicBlock.when, conditionalLogicBlock.operatorForConditions);
                        if (actionBlockContainingElement.action == Enums.ConditionalLogicAction.ShowBlocks) {
                            return !areConditionsAlreadyMet ? true : false;
                        }
                        else if (actionBlockContainingElement.action == Enums.ConditionalLogicAction.HideBlocks) {
                            return !areConditionsAlreadyMet ? false : true;
                        }
                        else {
                            return false;
                        }
                    }
                }
            }
            return false;
        },
        checkIfTheConditionsForAFormElementIsAlreadyFulfilled(conditions, operatorForConditions) {
            let conditionFulfilled = false;
            for (let condition of conditions) {
                let formElement = find(this.formElements, function (element) { return element.formElementId == condition.formElementId; });
                if (formElement != null) {
                    //If it is a question
                    if (formElement.elementType == Enums.FormElementType.Question) {
                        //finding linked child
                        formElement = find(this.formElements, function (element) { return element.linkedToId == condition.formElementId; });
                        if (formElement == null) {
                            return false;
                        }
                    }
                    let valueToCompare = null;
                    if (formElement.elementName != null && formElement.elementName != "") {
                        valueToCompare = JSON.parse(formElement.elementName);
                    }
                    if (formElement.elementType == Enums.FormElementType.MultipleChoice) {
                        if (condition.operator == Enums.WhenLogicOperator.Equal) {
                            conditionFulfilled = valueToCompare == condition.value;
                        }
                        else if (condition.operator == Enums.WhenLogicOperator.NotEqual) {
                            conditionFulfilled = valueToCompare != condition.value;
                        }
                    }
                    else if (formElement.elementType == Enums.FormElementType.Dropdown) {
                        if (condition.operator == Enums.WhenLogicOperator.Equal) {
                            conditionFulfilled = valueToCompare == condition.value;
                        }
                        else if (condition.operator == Enums.WhenLogicOperator.NotEqual) {
                            conditionFulfilled = valueToCompare != condition.value;
                        }
                    }
                    else if (formElement.elementType == Enums.FormElementType.Toggle) {
                        if (condition.operator == Enums.WhenLogicOperator.Equal) {
                            conditionFulfilled = valueToCompare == condition.value;
                        }
                        else if (condition.operator == Enums.WhenLogicOperator.NotEqual) {
                            conditionFulfilled = valueToCompare != condition.value;
                        }
                    }
                    else if (formElement.elementType == Enums.FormElementType.Checkbox) {
                        if (condition.operator == Enums.WhenLogicOperator.Equal) {
                            if (valueToCompare) {
                                conditionFulfilled = valueToCompare.includes(condition.value);
                            }
                        }
                        else if (condition.operator == Enums.WhenLogicOperator.NotEqual) {
                            if (valueToCompare) {
                                conditionFulfilled = !valueToCompare.includes(condition.value);
                            }
                        }
                        else {
                            conditionFulfilled = !conditionFulfilled;
                        }
                    }
                    if (conditionFulfilled && operatorForConditions == Enums.BooleanOperator.OR) {
                        return true;
                    }
                    else if (!conditionFulfilled && operatorForConditions == Enums.BooleanOperator.AND) {
                        return false;
                    }
                }
            }
            return conditionFulfilled;
        },
        checkConditionalLogicOnElementAfterChange(formPreviewElement) {
            if (this.conditionalLogicBlocks.length > 0) {
                let formElementIdsWithConditionFullfilled = [];
                for (let conditionalLogicBlock of this.conditionalLogicBlocks) {
                    let conditionFulfilled = false;
                    let operatorForConditions = conditionalLogicBlock.operatorForConditions;
                    let formElementIds = map(conditionalLogicBlock.when, 'formElementId');
                    let childFormElementIds = map(filter(this.formElements, function (element) { return element.linkedToId != null && element.linkedToId != "" && formElementIds.includes(element.linkedToId); }), 'formElementId');
                    formElementIds = formElementIds.concat(childFormElementIds);
                    let elementFound = formElementIds.includes(formPreviewElement.formElementId);
                    if (!elementFound) {
                        continue;
                    }
                    //Checking if all conditions have been fulfilled
                    for (let condition of conditionalLogicBlock.when) {
                        let formElement = find(this.formPreviewElements, function (element) { return element.formElementId == condition.formElementId; });
                        if (formElement != null && formElement.elementType == Enums.FormElementType.Question) {
                            let parentElementId = formElement.formElementId;
                            formElement = find(this.formPreviewElements, function (element) { return element.linkedToId == parentElementId; });
                        }
                        if (formElement != null) {
                            if (formElement.elementType == Enums.FormElementType.MultipleChoice) {
                                if (condition.operator == Enums.WhenLogicOperator.Equal) {
                                    conditionFulfilled = formElement.inputValue == condition.value;
                                }
                                else if (condition.operator == Enums.WhenLogicOperator.NotEqual) {
                                    conditionFulfilled = formElement.inputValue != condition.value;
                                }
                            }
                            else if (formElement.elementType == Enums.FormElementType.Dropdown) {
                                let dropdownValue = formElement.inputValue != null ? formElement.inputValue.elementOptionId : null;
                                if (condition.operator == Enums.WhenLogicOperator.Equal) {
                                    conditionFulfilled = dropdownValue == condition.value;
                                }
                                else if (condition.operator == Enums.WhenLogicOperator.NotEqual) {
                                    conditionFulfilled = dropdownValue != condition.value;
                                }
                            }
                            else if (formElement.elementType == Enums.FormElementType.Toggle) {
                                if (condition.operator == Enums.WhenLogicOperator.Equal) {
                                    conditionFulfilled = formElement.inputValue == condition.value;
                                }
                                else if (condition.operator == Enums.WhenLogicOperator.NotEqual) {
                                    conditionFulfilled = formElement.inputValue != condition.value;
                                }
                            }
                            else if (formElement.elementType == Enums.FormElementType.Checkbox) {
                                let inputVal;
                                if (formElement.inputValue != "" && typeof formElement.inputValue == 'string') {
                                    inputVal = JSON.parse(formElement.inputValue);
                                }
                                else {
                                    inputVal = [];
                                }
                                if (condition.operator == Enums.WhenLogicOperator.Equal) {
                                    conditionFulfilled = inputVal.includes(condition.value);
                                }
                                else if (condition.operator == Enums.WhenLogicOperator.NotEqual) {
                                    conditionFulfilled = !inputVal.includes(condition.value);
                                }
                            }
                            if (conditionFulfilled && operatorForConditions == Enums.BooleanOperator.OR) {
                                break;
                            }
                            else if (!conditionFulfilled && operatorForConditions == Enums.BooleanOperator.AND) {
                                break;
                            }
                        }
                    }
                    //When condition is fulfilled, the element in then needs to be modified
                    for (let action of conditionalLogicBlock.then) {
                        for (let formElementId of action.formElementIds) {
                            let indexOfElementToAffect = findIndex(this.formPreviewElements, function (element) { return element.formElementId == formElementId; });
                            let isFormElementConditionAlreadyFufilled = formElementIdsWithConditionFullfilled.includes(formElementId);
                            if (indexOfElementToAffect > -1 && !isFormElementConditionAlreadyFufilled) {
                                this.applyAction(indexOfElementToAffect, conditionFulfilled, action.action);
                                //checking if the affected element was a question
                                if (this.formPreviewElements[indexOfElementToAffect].elementType == Enums.FormElementType.Question) {
                                    //Finding linked/child element
                                    indexOfElementToAffect = findIndex(this.formPreviewElements, function (element) { return element.linkedToId == formElementId; });
                                    if (indexOfElementToAffect > -1) {
                                        this.applyAction(indexOfElementToAffect, conditionFulfilled, action.action);
                                    }
                                }
                                if (conditionFulfilled) {
                                    formElementIdsWithConditionFullfilled.push(formElementId);
                                }
                            }
                        }
                    }
                }
            }
        },
        applyAction(indexOfElementToAffect, conditionFulfilled, action) {
            if (action == Enums.ConditionalLogicAction.ShowBlocks) {
                let formPreviewElement = this.formPreviewElements[indexOfElementToAffect];
                this.$set(formPreviewElement, 'hideElement', conditionFulfilled ? false : true);
                if (!conditionFulfilled &&
                    formPreviewElement.elementType !== Enums.FormElementType.Text &&
                    formPreviewElement.elementType !== Enums.FormElementType.Question &&
                    formPreviewElement.elementType !== Enums.FormElementType.Heading1 &&
                    formPreviewElement.elementType !== Enums.FormElementType.Heading2 &&
                    formPreviewElement.elementType !== Enums.FormElementType.Heading3 &&
                    formPreviewElement.elementType !== Enums.FormElementType.Label) {
                    this.$set(formPreviewElement, 'inputValue', null);
                    this.checkConditionalLogicOnElementAfterChange(formPreviewElement);
                }
            }
            else if (action == Enums.ConditionalLogicAction.HideBlocks) {
                this.$set(this.formPreviewElements[indexOfElementToAffect], 'hideElement', conditionFulfilled ? true : false);
            }
            else if (action == Enums.ConditionalLogicAction.RequireAnswer) {
                this.$set(this.formPreviewElements[indexOfElementToAffect], 'isRequired', conditionFulfilled ? true : false);
            }
        },
        status(validation) {
            return {
                error: validation.$error,
                dirty: validation.$dirty,
            };
        },
        getFormToggleElementLabel(formElement) {
            let option1 = formElement.elementOptions != null && formElement.elementOptions[0] != null ? formElement.elementOptions[0].elementOptionName : "No";
            let option2 = formElement.elementOptions != null && formElement.elementOptions[1] != null ? formElement.elementOptions[1].elementOptionName : "Yes";
            let toggleOptionValue = formElement.inputValue;
            return !toggleOptionValue ? option1 : option2;
        },
        async onClickSubmitButton(partialSubmission = false) {
            //If already submitting response break
            if (this.formIsSubmitting) {
                return;
            }
            this.partialSubmissionForm = partialSubmission;
            let requiredFieldNotFilled = false;
            console.log(this.formPreviewElements);
            for (let j = 0; j < this.formPreviewElements.length; j++) {
                //Checkbox
                if (this.formPreviewElements[j].inputArray != null && this.formPreviewElements[j].elementType == "Checkbox") {
                    if (this.formPreviewElements[j].inputArray.length > 0)
                        this.formPreviewElements[j].inputValue = JSON.stringify(this.formPreviewElements[j].inputArray);
                }
                //Multi Choice
                else if (this.formPreviewElements[j].inputValue != null && this.formPreviewElements[j].elementType == "Multiple_Choice") {
                    if (typeof (this.formPreviewElements[j].inputValue) != "string") {
                        this.formPreviewElements[j].inputValue = JSON.parse(this.formPreviewElements[j].inputValue);
                    }
                    else {
                        if (this.formPreviewElements[j].inputValue != "") {
                            this.formPreviewElements[j].inputValue = JSON.parse(this.formPreviewElements[j].inputValue);
                        }
                    }
                }
                //Time
                else if (this.formPreviewElements[j].inputValue != null && this.formPreviewElements[j].elementType == "Time") {
                    if (typeof (this.formPreviewElements[j].inputValue) == "string") {
                        //if (this.formPreviewElements[j].inputValue != "") {
                        //}
                    }
                    else {
                        if (this.formPreviewElements[j].inputValue != "") {
                            this.formPreviewElements[j].inputValue = this.formPreviewElements[j].inputValue.id;
                        }
                    }
                }
                if (this.formPreviewElements[j].error == true) {
                    requiredFieldNotFilled = true;
                }
                //Check Validation
                if (this.$v.formPreviewElements != null && this.$v.formPreviewElements.$each != null && this.$v.formPreviewElements.$each[j] != null) {
                    let validation = this.$v.formPreviewElements.$each[j];
                    if (validation && !partialSubmission) {
                        validation.inputValue.$touch();
                        if (!requiredFieldNotFilled && validation.inputValue.$invalid) {
                            requiredFieldNotFilled = true;
                        }
                    }
                }
            }
            //If Validation Occurrs Return.
            if (requiredFieldNotFilled && !partialSubmission) {
                return;
            }
            //Convert Elements Values into String after Validations
            this.formPreviewValidationElements = cloneDeep(this.formPreviewElements);
            //ReInsert Conditional Logic Blocks          
            for (let logicBlock of this.conditionalLogicFormElements) {
                this.formPreviewValidationElements.splice(logicBlock.index, 0, logicBlock.conditionalLogicFormElements);
            }
            for (let i = 0; i < this.formPreviewValidationElements.length; i++) {
                //Multiple Choice
                if (this.formPreviewValidationElements[i].inputValue != null && this.formPreviewValidationElements[i].elementType == "Multiple_Choice") {
                    if (typeof (this.formPreviewValidationElements[i].inputValue) != "string") {
                        this.formPreviewValidationElements[i].inputValue = JSON.stringify(this.formPreviewValidationElements[i].inputValue);
                    }
                    else {
                        if (this.formPreviewValidationElements[i].inputValue != "") {
                            this.formPreviewValidationElements[i].inputValue = JSON.stringify(this.formPreviewValidationElements[i].inputValue);
                        }
                    }
                }
                //DropDown 
                else if (this.formPreviewValidationElements[i].inputValue != null && this.formPreviewValidationElements[i].elementType == "Dropdown") {
                    if (typeof (this.formPreviewValidationElements[i].inputValue) == "string") {
                        if (this.formPreviewValidationElements[i].inputValue != "") {
                            let parsedObject = JSON.parse(this.formPreviewValidationElements[i].inputValue);
                            this.formPreviewValidationElements[i].inputValue = JSON.stringify(parsedObject.elementOptionId);
                        }
                    }
                    else {
                        if (this.formPreviewValidationElements[i].inputValue != "") {
                            if (this.formPreviewValidationElements[i].inputValue.elementOptionId != undefined) {
                                this.formPreviewValidationElements[i].inputValue = JSON.stringify(this.formPreviewValidationElements[i].inputValue.elementOptionId);
                            }
                            else {
                                this.formPreviewValidationElements[i].inputValue = JSON.stringify(this.formPreviewValidationElements[i].inputValue);
                            }
                        }
                    }
                }
                //DropDown 
                else if (this.formPreviewValidationElements[i].inputValue != null && this.formPreviewValidationElements[i].elementType == Enums.FormElementType.Toggle) {
                    if (typeof (this.formPreviewValidationElements[i].inputValue) != "string") {
                        this.formPreviewValidationElements[i].inputValue = JSON.stringify(this.formPreviewValidationElements[i].inputValue);
                    }
                }
            }
            let formId = this.$route.params.id;
            let docId = this.$route.params.key;
            let ticketId = this.$route.params.ticketid;
            let requestModel = {
                id: docId,
                formId: formId,
                ticketId: ticketId,
                serviceProviderId: this.serviceProviderId,
                formTitle: this.formTitle,
                formElements: this.formPreviewValidationElements,
                version: this.version,
                state: partialSubmission == true ? 'Draft' : 'Submitted'
            };
            try {
                this.formIsSubmitting = true;
                let response = await FormServices.submitFormResponse(requestModel);
                if (response && response.submitted) {
                    if (!partialSubmission) {
                        this.initFormEmailData(response);
                    }
                    this.isFormFilled = true;
                }
                else {
                    this.$emit("callbackEvent_FormPreview", response);
                }
                this.formIsSubmitting = false;
            }
            catch (e) {
                this.formIsSubmitting = false;
                console.error(e);
            }
        },
        initFormEmailData(response) {
            this.submittedFormDate = new Date().toLocaleString();
            this.emailKey = response.emailKey;
            setTimeout(() => {
                var _a;
                this.emailHTML = (_a = document.getElementById(this.formEmailComponentId)) === null || _a === void 0 ? void 0 : _a.innerHTML;
                console.log(this.emailHTML);
            }, 0);
        },
        async partialSubmission() {
            this.onClickSubmitButton(true);
        },
        onClickSendCopyToEmail() {
            this.showEmailDialog = true;
        },
        async onSendEmail() {
            console.log(this.emailHTML);
            this.isSendingEmail = true;
            if (!this.emailHTML) {
                this.emailSent = false;
                this.onCloseEmailDialog();
                return;
            }
            let formId = this.$route.params.id;
            let requestModel = {
                emailAddress: this.emailAddress,
                emailHtml: this.emailHTML,
                formTitle: this.formTitle,
                formId: formId,
                serviceProviderId: this.serviceProviderId,
                subject: "Here's a copy of the form you just submitted"
            };
            try {
                this.emailSent = await FormServices.sendFormEmail(requestModel, { name: "emailKey", value: this.emailKey });
                if (this.emailSent)
                    this.emailKey = "";
            }
            catch (ex) {
                this.emailSent = false;
            }
            this.onCloseEmailDialog();
        },
        onCloseEmailDialog() {
            this.showEmailDialog = false;
            this.isSendingEmail = false;
        },
        emailStatus(validation) {
            return {
                error: validation.$error,
                dirty: validation.$dirty
            };
        },
    },
    created() {
        this.initiateFormPreviewElements();
    },
}) {
}

import store from '@/account/store/store';
import Form from '@/account/views/form.vue';
import vuetify from '@/common/components/plugins/vuetify-plugin';
import { initHttpService } from '@/common/helper/http-service/http-service';
import i18n from '@/common/locales';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';
import vuescroll from 'vuescroll/dist/vuescroll-native';
i18n.locale = 'en';
Vue.config.performance = true;
Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.use(vuescroll, {
    ops: {
        bar: {
            onlyShowBarOnScroll: false,
            keepShow: true,
            opacity: 0.5,
        },
        vuescroll: {
            wheelScrollDuration: 300,
            wheelDirectionReverse: false
        },
    },
});
const _routes = [
    { path: '/form/:id/key/:key', name: 'form', component: Form },
    { path: '/form/:id/ticket/:ticketid/key/:key', name: 'form', component: Form }
];
let router = new VueRouter({
    mode: 'history',
    routes: _routes
});
initHttpService(store, router);
var AppElement = document.getElementById("app");
if (AppElement == null) {
    document.getElementById("appContainer").innerHTML = "<div id='app'></div>";
}
new Vue({
    router: router,
    vuetify,
    i18n,
    el: '#app',
    render: h => h(require('./master.vue').default)
});

import { find, map } from "lodash";
import { DateTime } from "luxon";
import Vue from "vue";
import { Enums } from "../../common/helper/enums";
import { formElementOptions, formElementTypes, parseFormResponseElementForHeaderValue } from "../../common/model/form-model";
export default class FormResponseView extends Vue.extend({
    name: "FormResponseView",
    props: {
        formTitle: {
            type: String
        },
        formElements: {
            type: Array
        },
        submittedFormUTCDate: {
            type: String
        }
    },
    computed: {
        timeOptions() {
            let startTimeArray = [];
            let startTime = DateTime.utc().set({ hour: 7, minute: 0, second: 0 });
            for (let i = 0; i < 24; i++) {
                for (let j = 1; j <= 2; j++) {
                    if (j == 1) {
                        startTime = startTime.plus({ hour: 1 });
                    }
                    else {
                        startTime = startTime.plus({ minute: 30 });
                    }
                    let timeIn12HourFormat = startTime.toFormat("h:mm a");
                    startTimeArray.push({ id: timeIn12HourFormat, time: timeIn12HourFormat });
                }
            }
            return startTimeArray;
        },
        shortAnswerElementId() {
            return Enums.FormElementType.ShortAnswer;
        },
        longAnswerElementId() {
            return Enums.FormElementType.LongAnswer;
        },
        multipleChoiceElementId() {
            return Enums.FormElementType.MultipleChoice;
        },
        checkboxElementId() {
            return Enums.FormElementType.Checkbox;
        },
        numberElementId() {
            return Enums.FormElementType.Number;
        },
        emailElementId() {
            return Enums.FormElementType.Email;
        },
        phoneNumberElementId() {
            return Enums.FormElementType.PhoneNumber;
        },
        linkElementId() {
            return Enums.FormElementType.Link;
        },
        dateElementId() {
            return Enums.FormElementType.Date;
        },
        timeElementId() {
            return Enums.FormElementType.Time;
        },
        dropdownElementId() {
            return Enums.FormElementType.Dropdown;
        },
        heading1ElementId() {
            return Enums.FormElementType.Heading1;
        },
        heading2ElementId() {
            return Enums.FormElementType.Heading2;
        },
        heading3ElementId() {
            return Enums.FormElementType.Heading3;
        },
        questionElementId() {
            return Enums.FormElementType.Question;
        },
        labelElementId() {
            return Enums.FormElementType.Label;
        },
        textElementId() {
            return Enums.FormElementType.Text;
        },
        dividerElementId() {
            return Enums.FormElementType.Divider;
        },
        toggleElementId() {
            return Enums.FormElementType.Toggle;
        }
    },
    watch: {
        'submittedFormUTCDate': {
            handler() {
                this.initiateFormResponseElements();
            }
        },
    },
    /************************************/
    /* Data Section *
    /************************************/
    data() {
        return {
            formResponseElements: [],
            loadingFormResponses: false,
            hideFormResponse: true
        };
    },
    /************************************/
    /* Methods Section *
    /************************************/
    methods: {
        getTimeIn12HourFormat(time) {
            let timeArray = time.split(":");
            let hour = timeArray[0];
            let minute = timeArray[1];
            var ap = "AM";
            if (hour > 11) {
                ap = "PM";
            }
            if (hour > 12) {
                hour = hour - 12;
            }
            if (hour == 0) {
                hour = 12;
            }
            if (hour < 10 && hour.toString().length == 1) {
                hour = "0" + hour;
            }
            if (minute < 10 && minute.toString().length == 1) {
                minute = "0" + minute;
            }
            var timeString = hour + ':' + minute + ap;
            return timeString;
        },
        initiateFormResponseElements() {
            this.formResponseElements = [];
            this.loadingFormResponses = true;
            try {
                //let response: FormResponseByIdModel = await formService.getFormResponseByResponseId(responseContentId);
                //if (response != null) {
                let formElements = this.formElements;
                if (formElements != null && formElements.length > 0) {
                    let formElementOption = find(formElementOptions, function (formOption) { return formOption.blockName == "Layout Blocks"; });
                    let layoutElementTypes = map(formElementOption === null || formElementOption === void 0 ? void 0 : formElementOption.formElementTypes, 'id');
                    let formResponseHeaderList = [];
                    let formElementTypeList = formElementTypes();
                    let headerModel = {
                        headerName: "Submitted At",
                        headerValue: this.submittedFormUTCDate,
                        iconClass: "far fa-calendar-alt  secondary--text mr-1"
                    };
                    formResponseHeaderList.push(headerModel);
                    for (let formElement of formElements) {
                        if ((formElement.linkedToId == null || formElement.linkedToId == "") && !layoutElementTypes.includes(formElement.elementType)) {
                            let headerValueFormElement = find(formElements, function (element) {
                                return element.elementType != Enums.FormElementType.Question && (element.linkedToId == formElement.formElementId || (element.formElementId == formElement.formElementId && !layoutElementTypes.includes(element.elementType)));
                            });
                            let headerValue = parseFormResponseElementForHeaderValue(headerValueFormElement);
                            let iconClass = "";
                            if (headerValueFormElement != null) {
                                let elementType = headerValueFormElement.elementType;
                                let formTypeObject = find(formElementTypeList, function (type) { return type.id == elementType; });
                                if (formTypeObject != null) {
                                    iconClass = formTypeObject.iconClass;
                                }
                            }
                            if ((formElement.elementType == Enums.FormElementType.Link || (headerValueFormElement === null || headerValueFormElement === void 0 ? void 0 : headerValueFormElement.elementType) == Enums.FormElementType.Link) && headerValue != "" && headerValue != "-") {
                                headerValue = `<a href='${headerValue}' target="_blank">${headerValue}</a>`;
                            }
                            if ((formElement.elementType == Enums.FormElementType.Email || (headerValueFormElement === null || headerValueFormElement === void 0 ? void 0 : headerValueFormElement.elementType) == Enums.FormElementType.Email) && headerValue != "" && headerValue != "-") {
                                headerValue = `<a href='mailto:${headerValue}' target="_blank">${headerValue}</a>`;
                            }
                            let headerModel = {
                                headerName: formElement.placeholder,
                                headerValue: headerValue.replace(/\n/g, '<br>'),
                                iconClass: iconClass
                            };
                            formResponseHeaderList.push(headerModel);
                        }
                    }
                    this.formResponseElements = formResponseHeaderList;
                }
            }
            catch (e) {
                console.error("Error in fetching response content");
                console.error(e);
            }
            this.loadingFormResponses = false;
        },
        onClickHideFormResponse() {
            this.formResponseElements = [];
        }
    },
    created() {
        //this.initiateFormResponseElements();
    },
}) {
}

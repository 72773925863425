export var Enums;
(function (Enums) {
    let ServiceProviderType;
    (function (ServiceProviderType) {
        ServiceProviderType[ServiceProviderType["MSP"] = 1] = "MSP";
        ServiceProviderType[ServiceProviderType["Enterprise"] = 2] = "Enterprise";
    })(ServiceProviderType = Enums.ServiceProviderType || (Enums.ServiceProviderType = {}));
    let FormElementType;
    (function (FormElementType) {
        FormElementType["ShortAnswer"] = "Short_Answer";
        FormElementType["LongAnswer"] = "Long_Answer";
        FormElementType["MultipleChoice"] = "Multiple_Choice";
        FormElementType["Checkbox"] = "Checkbox";
        FormElementType["Number"] = "Number";
        FormElementType["Email"] = "Email";
        FormElementType["PhoneNumber"] = "Phone_Number";
        FormElementType["Link"] = "Link";
        FormElementType["Date"] = "Date";
        FormElementType["Time"] = "Time";
        FormElementType["Dropdown"] = "Dropdown";
        FormElementType["Heading1"] = "Heading_1";
        FormElementType["Heading2"] = "Heading_2";
        FormElementType["Heading3"] = "Heading_3";
        FormElementType["Label"] = "Label";
        FormElementType["Text"] = "Text";
        FormElementType["Divider"] = "Divider";
        FormElementType["SubmitButton"] = "Submit_Button";
        FormElementType["FormSubmissionMessage"] = "Form_Submission_Message";
        FormElementType["Toggle"] = "Toggle";
        FormElementType["Question"] = "Question";
        FormElementType["QuestionAndShortAnswer"] = "Question_Short_Answer";
        FormElementType["QuestionAndLongAnswer"] = "Question_Long_Answer";
        FormElementType["QuestionAndCheckbox"] = "Question_Checkbox";
        FormElementType["QuestionAndNumber"] = "Question_Number";
        FormElementType["QuestionAndEmail"] = "Question_Email";
        FormElementType["QuestionAndLink"] = "Question_Link";
        FormElementType["QuestionAndDate"] = "Question_Date";
        FormElementType["QuestionAndTime"] = "Question_Time";
        FormElementType["QuestionAndDropdown"] = "Question_Dropdown";
        FormElementType["QuestionAndMultipleChoice"] = "Question_MultipleChoice";
        FormElementType["QuestionAndPhoneNumber"] = "Question_PhoneNumber";
        FormElementType["QuestionAndToggle"] = "Question_Toggle";
        FormElementType["ConditionalLogic"] = "Conditional_Logic";
    })(FormElementType = Enums.FormElementType || (Enums.FormElementType = {}));
    let WhenLogicOperator;
    (function (WhenLogicOperator) {
        WhenLogicOperator["Equal"] = "eq";
        WhenLogicOperator["NotEqual"] = "ne";
    })(WhenLogicOperator = Enums.WhenLogicOperator || (Enums.WhenLogicOperator = {}));
    let BooleanOperator;
    (function (BooleanOperator) {
        BooleanOperator["AND"] = "and";
        BooleanOperator["OR"] = "or";
    })(BooleanOperator = Enums.BooleanOperator || (Enums.BooleanOperator = {}));
    let ConditionalLogicAction;
    (function (ConditionalLogicAction) {
        ConditionalLogicAction["ShowBlocks"] = "Show_Blocks";
        ConditionalLogicAction["HideBlocks"] = "Hide_Blocks";
        ConditionalLogicAction["RequireAnswer"] = "Require_Answer";
    })(ConditionalLogicAction = Enums.ConditionalLogicAction || (Enums.ConditionalLogicAction = {}));
})(Enums || (Enums = {}));

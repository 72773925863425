import { Enums } from '@/common/helper/enum';
import { filter, find, map } from 'lodash';
export class FormElement {
}
export class ElementOption {
}
export class FormSubmitResponseModel {
}
export class FormPreviewModel {
}
export class FormSubmitResponseRequestModel {
}
export class formContentResponseModel {
}
export class FormRequestModel {
}
export class FormElementType {
}
export class FormElementOption {
}
export class FormSettingRequestModel {
}
export class ConditionalLogic {
}
export class Condition {
}
export class Action {
}
export class TicketFormResponseModel {
}
export class FormSubmissionEmailModel {
}
export const sizeOfGuid = 6;
export const customTagIdCharacters = "0123456789";
export const formElementOptions = [
    {
        blockName: "Questions",
        formElementTypes: [
            { id: Enums.FormElementType.QuestionAndShortAnswer, name: "Question + Short Answer", iconClass: "far fa-grip-lines xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.QuestionAndLongAnswer, name: "Question + Long Answer", iconClass: "far fa-bars xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.QuestionAndMultipleChoice, name: "Question + Multiple Choice", iconClass: "far fa-check-circle xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.QuestionAndCheckbox, name: "Question + Checkbox", iconClass: "far fa-check-square xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.QuestionAndNumber, name: "Question + Number", iconClass: "far fa-hashtag xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.QuestionAndEmail, name: "Question + Email", iconClass: "far fa-envelope xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.QuestionAndPhoneNumber, name: "Question + Phone number", iconClass: "far fa-phone flipicon xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.QuestionAndLink, name: "Question + Link", iconClass: "far fa-link xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.QuestionAndDate, name: "Question + Date", iconClass: "far fa-calendar-alt xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.QuestionAndTime, name: "Question + Time", iconClass: "far fa-clock xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.QuestionAndDropdown, name: "Question + Dropdown", iconClass: "far fa-angle-down xlg--text secondary--text mr-1" }
        ]
    },
    {
        blockName: "Layout Blocks",
        formElementTypes: [
            { id: Enums.FormElementType.Heading1, name: "Heading 1", iconClass: "far fa-h1 xlg--text secondary--text" },
            { id: Enums.FormElementType.Heading2, name: "Heading 2", iconClass: "far fa-h2 xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.Heading3, name: "Heading 3", iconClass: "far fa-h3 xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.Label, name: "Label", iconClass: "far fa-tag xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.Text, name: "Text", iconClass: "far fa-divide xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.Divider, name: "Divider", iconClass: "fas fa-horizontal-rule xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.FormSubmissionMessage, name: `'Thank you' page`, iconClass: "fal fa-smile xlg--text secondary--text mr-1" }
        ]
    },
    {
        blockName: "Input Blocks",
        formElementTypes: [
            { id: Enums.FormElementType.ShortAnswer, name: "Short Answer", iconClass: "far fa-grip-lines xlg--text secondary--text" },
            { id: Enums.FormElementType.LongAnswer, name: "Long Answer", iconClass: "far fa-bars xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.MultipleChoice, name: "Multiple Choice", iconClass: "far fa-check-circle xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.Checkbox, name: "Checkbox", iconClass: "far fa-check-square xlg--text  secondary--text mr-1" },
            { id: Enums.FormElementType.Number, name: "Number", iconClass: "far fa-hashtag xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.Email, name: "Email", iconClass: "far fa-envelope xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.PhoneNumber, name: "Phone Number", iconClass: "far fa-phone flipicon xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.Link, name: "Link", iconClass: "far fa-link xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.Date, name: "Date", iconClass: "far fa-calendar-alt xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.Time, name: "Time", iconClass: "far fa-clock xlg--text secondary--text mr-1" },
            { id: Enums.FormElementType.Dropdown, name: "Dropdown", iconClass: "far fa-angle-down xlg--text secondary--text mr-1" }
        ]
    }
];
export const formElementTypes = () => {
    let formElementTypesInArray = map(formElementOptions, 'formElementTypes');
    let types = [];
    for (let type of formElementTypesInArray) {
        types = types.concat(type);
    }
    return types;
};
export function parseFormResponseElementForHeaderValue(formElement) {
    if (formElement != null) {
        if (formElement.elementType == Enums.FormElementType.Checkbox) {
            //Array expected
            if (formElement.inputValue != null && formElement.inputValue != "") {
                let selectedValues = JSON.parse(formElement.inputValue);
                let selectedElementOptions = filter(formElement.elementOptions, function (elementOption) {
                    return selectedValues.includes(elementOption.elementOptionId);
                });
                let elementOptionNames = map(selectedElementOptions, 'elementOptionName');
                return elementOptionNames.join(",");
            }
            else {
                return "-";
            }
        }
        else if (formElement.elementType == Enums.FormElementType.MultipleChoice) {
            if (formElement.inputValue != null && formElement.inputValue != "") {
                let elementOptionId = JSON.parse(formElement.inputValue);
                let selected = find(formElement.elementOptions, function (elementOption) {
                    return elementOptionId == elementOption.elementOptionId;
                });
                if (selected != null) {
                    return selected.elementOptionName;
                }
                else {
                    return "-";
                }
            }
            else {
                return "-";
            }
        }
        else if (formElement.elementType == Enums.FormElementType.Dropdown) {
            if (formElement.inputValue != null && formElement.inputValue != "") {
                let elementOptionId = JSON.parse(formElement.inputValue);
                let selected = find(formElement.elementOptions, function (elementOption) {
                    return elementOptionId == elementOption.elementOptionId;
                });
                if (selected != null) {
                    return selected.elementOptionName;
                }
                else {
                    return "-";
                }
            }
            else {
                return "-";
            }
        }
        else if (formElement.elementType == Enums.FormElementType.Toggle) {
            //Boolean expected
            if (formElement.inputValue != null && formElement.inputValue != "") {
                let toggleValue = JSON.parse(formElement.inputValue);
                let option1 = formElement.elementOptions != null && formElement.elementOptions[0] != null ? formElement.elementOptions[0].elementOptionName : "No";
                let option2 = formElement.elementOptions != null && formElement.elementOptions[1] != null ? formElement.elementOptions[1].elementOptionName : "Yes";
                if (!toggleValue) {
                    return option1;
                }
                else {
                    return option2;
                }
            }
            else {
                return "-";
            }
        }
        else {
            if (formElement.inputValue != null && formElement.inputValue != "") {
                return formElement.inputValue;
            }
            else {
                return "-";
            }
        }
    }
    else {
        return "-";
    }
}

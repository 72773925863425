import { HttpService } from '@/common/helper/http-service/http-service';
export var HttpHelper;
(function (HttpHelper) {
    //const headers: any = {
    //    "Access-Control-Allow-Origin": "*",
    //    "Access-Control-Allow-Headers": "X-Custom-Header",
    //    "Access-Control-Allow-Methods": "GET",
    //    "Access-Control-Allow-Credentials": "true"
    //};
    let isLocalhost = window.location.href.indexOf('localhost:') != -1;
    function postData(url, params) {
        return new Promise((resolve, reject) => {
            let config = {
                headers: { 'Content-Type': 'application/json' }
            };
            HttpService.post(url, JSON.stringify(params), config)
                .then(response => response.data)
                .then(data => {
                resolve(data);
            })
                .catch(error => {
                reject(error);
            });
        });
    }
    HttpHelper.postData = postData;
    function postDataWithAdditionalHeader(url, params, header) {
        return new Promise((resolve, reject) => {
            let config = {
                headers: { 'Content-Type': 'application/json' }
            };
            if (header) {
                config.headers[header.name] = header.value;
            }
            HttpService.post(url, JSON.stringify(params), config)
                .then(response => response.data)
                .then(data => {
                resolve(data);
            })
                .catch(error => {
                reject(error);
            });
        });
    }
    HttpHelper.postDataWithAdditionalHeader = postDataWithAdditionalHeader;
    function patchData(url, params) {
        return new Promise((resolve, reject) => {
            let config = {
                headers: { 'Content-Type': 'application/json' }
            };
            HttpService.patch(url, JSON.stringify(params), config)
                .then(response => response.data)
                .then(data => {
                resolve(data);
            })
                .catch(error => {
                reject(error);
            });
        });
    }
    HttpHelper.patchData = patchData;
    function postBlobData(url, params) {
        return new Promise((resolve, reject) => {
            let config = {
                headers: { 'Content-Type': 'multipart/form-data' },
            };
            HttpService.post(url, JSON.stringify(params), config)
                .then(response => response.data)
                .then(data => {
                resolve(data);
            })
                .catch(error => {
                //Error 
            });
        });
    }
    HttpHelper.postBlobData = postBlobData;
    function postDataWithHeader(url, params, config) {
        return new Promise((resolve, reject) => {
            HttpService.post(url, JSON.stringify(params), config)
                .then(response => response.data)
                .then(data => {
                resolve(data);
            })
                .catch(error => {
                //Error 
                reject();
            });
        });
    }
    HttpHelper.postDataWithHeader = postDataWithHeader;
    function deleteData(url, params) {
        return new Promise((resolve, reject) => {
            let config = {
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(params),
            };
            HttpService.delete(url, config)
                .then(response => response.data)
                .then(data => {
                resolve(data);
            })
                .catch(error => {
                //Error 
                reject();
            });
        });
    }
    HttpHelper.deleteData = deleteData;
    function getData(url, params = null) {
        return new Promise((resolve, reject) => {
            HttpService.get(url, { data: params })
                .then(response => response.data)
                .then(data => {
                resolve(data);
            })
                .catch(error => {
                reject();
            });
        });
    }
    HttpHelper.getData = getData;
    function getDataWithHeader(url, config) {
        return new Promise((resolve, reject) => {
            HttpService.get(url, config)
                .then(response => response.data)
                .then(data => {
                resolve(data);
            })
                .catch(error => {
                reject();
            });
        });
    }
    HttpHelper.getDataWithHeader = getDataWithHeader;
    function postFormData(url, formData) {
        return new Promise((resolve, reject) => {
            let config = {
                headers: { 'Content-Type': 'multipart/form-data' },
            };
            HttpService.post(url, formData, config)
                .then(response => response.data)
                .then(data => {
                resolve(data);
            })
                .catch(error => {
                reject();
            });
        });
    }
    HttpHelper.postFormData = postFormData;
    function IsTokenExpired(response) {
        if (response.isTokenExpired) {
            var url = window.location.href;
            var arr = url.split("/");
            var Domain = arr[0] + "//" + arr[2];
            window.location.href = Domain + "/" + "Session/SigIn?CurrentController=" + arr[3];
            return true;
        }
        return false;
    }
})(HttpHelper || (HttpHelper = {}));
